import L from "leaflet";
import { MapControl, withLeaflet } from "react-leaflet";
import Requests from '../../../services/requests';

class LandUseLegend extends MapControl {

  constructor(props) {
    super(props);
  }

  createLeafletElement() {
  }

  async createLegend() {
    if (this.legend) {
      this.legend.remove();
    }
    this.legend = L.control({ name: "landUseLegend", position: "bottomright" });

    const landUseUrl = '/legends/landuse/';

    const bounds = this.props.leaflet.map.getBounds();
    const swLat = bounds.getSouthWest().lat;
    const swLon = bounds.getSouthWest().lng;
    const neLat = bounds.getNorthEast().lat;
    const neLon = bounds.getNorthEast().lng;
    const url = landUseUrl + swLat + "," + swLon + "," + neLat + "," + neLon;

    const labels = await Requests.get(url)
      .then((jsonData) => {
        if (jsonData) {
            // if response data has type value 'image' render out a link as the legend image can be quite big
            if (jsonData.type === 'image') {
              return [
                "<h4>" + this.props.layerTitle + "</h4>",
                '<a href=' + jsonData.data + ' onclick="return newWindowPopup(this)">' + this.props.legendTrans + '</a>'
              ]
            }

            let labelsToAdd = [];
            let colormap = jsonData.Legend[0].rules[0].symbolizers[0].Raster.colormap;

            labelsToAdd.push("<h4>" + this.props.layerTitle + "</h4>");

            for (let i = 0; i < colormap.entries.length; i++) {
              let entry = colormap.entries[i]; // eslint-disable-line security/detect-object-injection

              labelsToAdd.push(
                '<i style="background:' +
                entry.color +
                '"></i> ' +
                entry.label
              );
            }
            return labelsToAdd;
          }      
        })
      .catch((error) => {
        console.error(error);
        let labels = [];
        labels.push("<b>No Land Cover Data</b>");
        return labels;
      });

    this.legend.onAdd = () => {
      const div = L.DomUtil.create("div", "data-legend legend");

      div.innerHTML = labels.join("<br>");
      return div;
    };

  }

  addLegend = async (event) => {
    if (event.layer.options.id === 'landCoverLayer') {
      if (!this.legend) {
        await this.createLegend();
      }
      this.legend.addTo(this.props.leaflet.map);
    }
  }

  removeLegend = (event) => {
    if (event.layer.options.id === 'landCoverLayer') {
      this.legend.remove();
      this.legend = null;
    }
  }

  componentDidMount() {
    this.props.leaflet.map.on('overlayadd', this.addLegend);
    this.props.leaflet.map.on('overlayremove', this.removeLegend);
  }

  componentWillUnmount() {
    // Needed to explicitly override MapControl componentWillUnmount method
  }

}

export default withLeaflet(LandUseLegend);
